import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {CardList, CreditCard, Cash, Person, PersonDash, PersonBadge, PersonHeart, PersonWorkspace, PersonBoundingBox} from 'react-bootstrap-icons'
import './styles.css'
import { green } from '../../utils/constants';


function walletSection(){
    return(
        <Container className='pt-5 pb-5 section-height' id="howitworks">
    
        </Container>
    )
}

export default walletSection