import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as partners from "../../assets/partners";
import Team from "../../assets/partners/partn";
import "./styles.css";
function Partners() {
  return (
    <Container className="p-5 " id="team">
      <div className="mb-5">
        <Row>
          <div className="vh-10">
            <h2>Team</h2>
          </div>
        </Row>
        <div className="h-1 mx-auto mt-3 underline"></div>
      </div>
      <Row>
        <div className="team">
          {Team.part.map(({ name, image, email, position }) => (
            <Col md={4} className="mb-5">
              <div className="card-body">
                <div className="card-image">
                  {/* <img src={image} alt={name} className='img-fluid'/> */}
                </div>
                <div className="card-text">
                  <h3>{name}</h3>
                  <p>{email}</p>
                  <p>{position}</p>
                </div>
              </div>
            </Col>
          ))}
        </div>
      </Row>
    </Container>
  );
}

export default Partners;
