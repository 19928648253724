import React from "react";
// import { design } from '../../assets';
import "./styles.css";
import Button from "react-bootstrap/Button";

function HowSection() {
  return (
    <div className="d-block smallDevice">
      <div className="mobileView">
        <div id="farmwallet" className="how-section">
          <div className="h-100 how-inn">
            <h4 className="bosskids">Mission Statement</h4>
            <div
              className="h-1 mx-auto mt-3 underline"
              style={{ backgroundColor: "#ffffff" }}
            ></div>
            <p className="our_bilive mt-5">
              To empower young artists and crafters by providing them with a
              vibrant online marketplace to showcase and sell their creative
              products. We strive to cultivate a supportive community that
              fosters artistic expression, entrepreneurship, and personal
              growth. By offering a platform specifically designed for young
              talents, we aim to inspire confidence, encourage innovation, and
              nurture the next generation of creative leaders.
            </p>
            <div className="mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowSection;
