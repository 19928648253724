import React from 'react';
import AboutSection from '../../components/aboutSection';
import HomeSlider from '../../components/carousel';
import Footer from '../../components/footer';
import HowSection from '../../components/howSection';
import Partners from '../../components/partners';
import WalletSection from '../../components/walletSection';

function HomePage(){
    return(
        <div>
           <HomeSlider />
           <AboutSection />
           <WalletSection />
           <HowSection />
           <Partners />
           <Footer />

        </div>
    )
}

export default HomePage;