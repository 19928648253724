import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { logo } from "../../assets";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";

function NavBarCollapsible() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      sticky="top"
      variant="light"
    >
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            id="logo"
            src={logo}
            alt="logo"
            style={{ width: 50, height: 50 }}
          />
        </Navbar.Brand>

        {/* Replace the default Navbar.Toggle with MenuIcon */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <MenuIcon />
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="d-flex gap-5">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">Artwork</Nav.Link>
            <Nav.Link href="#contact">About Us</Nav.Link>
            <Nav.Link href="#team">Our Team</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarCollapsible;
