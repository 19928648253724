import { slide1, slide2, slide3,slide5,slide6, slide7, slide8, slide9, slide10 } from '../../assets';

const slides=[
    {image:slide1,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide2,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide3,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide5,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide6,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide7,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide8,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide9,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
    {image:slide10,header1:'Welcome to Boss kids,',header2:' the online marketplace for young artists and crafters!'},
]

export {slides}