const Team = {
    part: [
        {
            name: 'Tiffany Ramirez',
            image: '/images/tiffany.jpg',
            email: 'tiffany@bosskids.net',
            position: 'CEO',
        },
        {
            name: 'Adam Muhammed Muhideen',
            image: '/images/adam.jpg',
            email: 'adam@bosskids.net',
            position: 'CTO',
        },
       
    ]
}

export default Team