import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { green } from "../../utils/constants";
import { Container } from "react-bootstrap";
import logo from "./Logo.jpg";
import { Facebook, Linkedin, Twitter } from "react-bootstrap-icons";
import "./styles.css";

// const useStyles = makeStyles(() => ({

//     logo: {
//         maxWidth: '100%',
//         maxHeight: '80%',
//     },
// }));

function Footer() {
  // const classes = useStyles();
  return (
    <div
      className="pt-5"
      id="contact"
      style={{ backgroundColor: green, color: "#fff" }}
    >
      <Container>
        <Row>
          <Col md={3}>
            <div>
              <h5>Contact Us</h5>
              <p>support@bosskids.net</p>
            </div>
          </Col>
          <Col md={3}>
            <div>
              {/* <h5>SAY HELLO</h5> */}
              <p></p>
            </div>
          </Col>
          <Col md={3}>
            <div>
              {/* <h5>CAREERS</h5> */}
              <p></p>
            </div>
          </Col>
          <Col md={{ span: 2, offset: 1 }}>
            <Stack gap={3} direction="horizontal">
              <a href="" target="__blank">
                <Linkedin size={25} color="#fff" />
              </a>
              <a href="" target="__blank">
                <Twitter size={25} color="#fff" />
              </a>
              <a href="" target="__blank">
                <Facebook size={25} color="#fff" />
              </a>
              <div className="logox">
                <a href="" target="__blank">
                  <img
                    src={logo}
                    alt="SECTIGO Trust Seal"
                    className="img-fluid"
                  />
                </a>
                <a href="https://www.positivessl.com/" target="__blank">
                  <img
                    src="https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png"
                    alt="PositiveSSL Trust Seal"
                    className="img-fluid"
                  />
                </a>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
