import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { slide1, slide2 } from "../../assets";
import { slides } from "./slider-data";
import "./styles.css";

function renderItems({ image, header1, header2 }) {
  return (
    <Carousel.Item>
      <div className="overlay"></div>
      <div className="mobileView">
        <img
          className="d-block h-100 smallDevice"
          src={image}
          alt="First slide"
        />
      </div>
      <Carousel.Caption>
        <div className="header-container">
          <h1 className="header-mobile">{header1}</h1>
          <h1 className="header-mobile">{header2}</h1>
        </div>
        <p></p>
        <br />
        <div id="buttons" className="flex-container">
          <a href="https://customer.bosskids.net" className="flex-item">
            <Button className="rounded-pill lg-button" variant="success">
              Customer Login
            </Button>
          </a>
          <a href="https://parent.bosskids.net/" className="flex-item">
            <Button className="rounded-pill lg-button" variant="success">
              Parent Login
            </Button>
          </a>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  );
}

const HomeSlider = () => {
  return <Carousel>{slides.map(renderItems)}</Carousel>;
};

export default HomeSlider;
