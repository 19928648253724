import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
// import Col from 'react-bootstrap/Col';
import "./styles.css";
// import {Cash,Clock,HandThumbsUp, Speedometer2, Wallet2} from 'react-bootstrap-icons'
// import { green } from '../../utils/constants';
import data from "../../assets/data";

function About() {
  return (
    <Container className="pt-5 pb-5 " id="about">
      <Row>
        <div className="vh-10 mt-5">
          <h2>Featured Art and Craft</h2>
        </div>
      </Row>
      <div className="h-1 mx-auto mt-3 underline"></div>
      <Row className="mt-5">
        <div>
          <LiteYouTubeEmbed
            id="B3uz9geGVPI"
            title="Iron Scrubber Painting Technique for Beginners / Acrylic Painting"
          />
        </div>
      </Row>
    </Container>
  );
}

export default About;
